@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.title::before {
  content: '';
  position: absolute;
  background: #f59e0b;
  width: 50px;
  height: 8px;
  bottom: -17px;
  right: 49%;
  border-radius: 20px;
}
.one {
  width: 50%;
  height: 200px;
}
.hero {
  background: linear-gradient(180deg, #fff, #fff 37.5%, #f1f2f4);
}
.slide-x {
  height: 100%;
  width: 100%;
}
.slick-arrow,
.slick-prev {
  display: none !important;
}

.slide-brand {
  background: blue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
